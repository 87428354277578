import { Controller } from "stimulus";
import mixpanel from "mixpanel-browser";

export default class extends Controller {
  static values = {
    cdpTrackerToken: String,
    cdpTrackerIdentity: String,
  };

  static outlets = ["plans--share-cards"];

  static targets = [
    "meatType",
    "shareCard",
    "shareCardsContainer",
    "shareOptionsForm",
    "shareOptions",
    "shareOption",
    "legacyDiscountMessage",
    "shellfishInclusionRadioButton",
  ];

  connect() {
    this.refreshActiveShareOptionsForm(null);
  }

  // NOTE: this is called when a share card is clicked
  switchSelectedShareType(event) {
    // NOTE: event.target is the innermost element clicked
    //       event.currentTarget is the target on which the listener is installed
    let selectedShareType = event.currentTarget.dataset.shareType;
    this.plansShareCardsOutlet.toggleActiveShareCards(selectedShareType);
    this._switchSelectedShareTypeTo(
      selectedShareType,
      this._shareOptionsFormGroupsBasedOnCurrentSelections(selectedShareType),
    );
  }

  // NOTE: Called when user changes selected share size
  switchSelectedShare(event) {
    this._setMinWeight(event.currentTarget);
  }

  // NOTE: Called when user changes clicks shellfish included / not
  //       and when user selects meat type
  refreshActiveShareOptionsForm(_event) {
    const initialActiveShareOptionsTarget = this.shareOptionsTargets.filter(
      (target) => target.dataset.disabled == "false",
    )[0];
    const selectTargetGroups =
      this._shareOptionsFormGroupsBasedOnCurrentSelections(
        initialActiveShareOptionsTarget.dataset.shareType,
      );
    const activeOptionsGroup = selectTargetGroups.filter(
      ({ active, matchesMeatCombos, matchesShellfish }) => {
        return active && matchesMeatCombos && matchesShellfish;
      },
    )[0];

    if (activeOptionsGroup == undefined) {
      this.plansShareCardsOutlet.toggleActiveShareCards(null);
    } else if (activeOptionsGroup.target != initialActiveShareOptionsTarget) {
      this._selectClosestShareOnMinWeight(activeOptionsGroup.target);
      this.plansShareCardsOutlet.toggleActiveShareCards(
        activeOptionsGroup.target.dataset.shareType,
      );
      this._switchSelectedShareTypeTo(
        activeOptionsGroup.target.dataset.shareType,
        selectTargetGroups,
      );
    }
    this.plansShareCardsOutlet.toggleApplicableShares(
      this._activeShareTypes(selectTargetGroups),
    );
  }

  _switchSelectedShareTypeTo(selectedShareType, selectTargetGroups) {
    for (const selectTargetGroup of selectTargetGroups) {
      const { target } = selectTargetGroup;

      if (selectTargetGroup.active) {
        this._enableAllNestedInputs(target);
        this._showOrHideLegacyDiscountText(target);
      } else {
        this._disableAllNestedInputs(target);
      }
    }
    this._trackOnCustomerDataPlatform({
      "Share Type": selectedShareType,
    });
  }

  _setMinWeight(shareOptionTarget) {
    let shareMinWeight = shareOptionTarget.dataset.minWeight;
    this.shareCardsContainerTarget.setAttribute(
      "data-share-min-weight",
      shareMinWeight,
    );
  }

  _shareOptionsFormGroupsBasedOnCurrentSelections(selectedShareType) {
    return this.shareOptionsTargets.reduce((acc, target) => {
      const shareOptionsFormTarget = target.closest(
        'div[data-plans--share-selection-signup-target="shareOptionsForm"]',
      );

      const matchesMeatCombos = this._selectedMeatTypesMatchesMeatCombo(
        shareOptionsFormTarget.dataset.meatCombos,
      );

      const matchesShellfish = this._selectedShellfishInclusionMatches(
        shareOptionsFormTarget.dataset.shellfish,
      );

      const active =
        target.dataset.shareType == selectedShareType &&
        matchesMeatCombos &&
        matchesShellfish;

      acc.push({
        target: target,
        shareOptionsFormTarget: shareOptionsFormTarget,
        active: active,
        matchesMeatCombos: matchesMeatCombos,
        matchesShellfish: matchesShellfish,
      });

      return acc;
    }, []);
  }

  _selectClosestShareOnMinWeight(shareOptionsTarget) {
    let options = Array.from(shareOptionsTarget.querySelectorAll("input"));
    let idealWeight = this.shareCardsContainerTarget.dataset.shareMinWeight;
    let closestWeight = options.reduce((previous, current) => {
      return Math.abs(current.dataset.minWeight - idealWeight) <
        Math.abs(previous.dataset.minWeight - idealWeight)
        ? current
        : previous;
    });

    closestWeight.checked = true;
  }

  _enableAllNestedInputs(target) {
    const shareOptionsFormTarget = target.closest(
      'div[data-plans--share-selection-signup-target="shareOptionsForm"]',
    );

    for (const input of target.getElementsByTagName("input")) {
      if (/quantity/.test(input.name)) {
        input.setAttribute("value", 1);
      }

      input.removeAttribute("disabled");
      input.classList.remove("hidden");
    }

    for (const input of target.getElementsByTagName("select")) {
      input.removeAttribute("disabled");
      input.classList.remove("hidden");
    }

    target.setAttribute("data-disabled", "false");
    shareOptionsFormTarget.classList.remove("hidden");
  }

  _disableAllNestedInputs(target) {
    const shareOptionsFormTarget = target.closest(
      'div[data-plans--share-selection-signup-target="shareOptionsForm"]',
    );

    for (const input of target.getElementsByTagName("input")) {
      if (/quantity/.test(input.name)) {
        input.setAttribute("value", 0);
      }

      input.setAttribute("disabled", "disabled");
      input.classList.add("hidden");
    }

    for (const input of target.getElementsByTagName("select")) {
      input.setAttribute("disabled", "disabled");
      input.classList.add("hidden");
    }

    target.setAttribute("data-disabled", "true");
    shareOptionsFormTarget.classList.add("hidden");
  }

  _selectedOrDefaultShareText(shareOptionTarget) {
    const selectedShareOption =
      shareOptionTarget.options[shareOptionTarget.selectedIndex];
    if (selectedShareOption.value == "") {
      for (const option of shareOptionTarget.options) {
        if (option.value != "") {
          return option.text;
        }
      }
    } else {
      return selectedShareOption.text;
    }
  }

  _showOrHideLegacyDiscountText(shareOptionTarget) {
    if (shareOptionTarget.dataset.showLegacyDiscount == "true") {
      this.legacyDiscountMessageTarget.classList.remove("hidden");
    } else {
      this.legacyDiscountMessageTarget.classList.add("hidden");
    }
  }

  _selectedMeatTypesMatchesMeatCombo(meatCombo) {
    let selectedMeatTypeNames = new Set(
      this.meatTypeTargets
        .filter((target) => target.checked)
        .map((target) => target.dataset.meatType),
    );
    let optionMeatCombos = JSON.parse(meatCombo).map((arr) => new Set(arr));

    return optionMeatCombos.some((combo) => {
      return (
        selectedMeatTypeNames.size === combo.size &&
        [...selectedMeatTypeNames].every((meatType) => combo.has(meatType))
      );
    });
  }

  _selectedShellfishInclusionMatches(formsShellfishValue) {
    if (formsShellfishValue == null || formsShellfishValue == undefined) {
      return true;
    }

    const selectedShellfishInclusionElement =
      this.shellfishInclusionRadioButtonTargets.find((element) => {
        return element.checked;
      });

    const selectedShellfishInclusion = selectedShellfishInclusionElement
      ? selectedShellfishInclusionElement.value
      : "false";

    return selectedShellfishInclusion == formsShellfishValue;
  }

  _trackOnCustomerDataPlatform(profileData) {
    if (this.hasCdpTrackerIdentityValue && this.hasCdpTrackerTokenValue) {
      try {
        mixpanel.init(this.cdpTrackerTokenValue);
        mixpanel.identify(this.cdpTrackerIdentityValue);
        mixpanel.people.set(profileData);
      } catch (e) {}
    }
  }

  _activeShareTypes(selectTargetGroups) {
    return selectTargetGroups.reduceRight((acc, selectTargetGroup) => {
      const shareType = selectTargetGroup.target.dataset.shareType;
      if (selectTargetGroup.matchesMeatCombos && acc.indexOf(shareType) == -1) {
        acc.push(shareType);
      }
      return acc;
    }, []);
  }
}
