import { Controller } from "stimulus";
import "../utils/klaviyo.js";

export default class extends Controller {
  static values = { identifier: Object };

  connect() {
    setTimeout(() => {
      klaviyo.identify(this.identifierValue, this._track_page_visit());
    }, 250);
  }

  track(event) {
    let options = JSON.parse(event.target.dataset.mpTrackerDataValue);
    let eventName = event.target.dataset.mpTrackerEventNameValue;
    klaviyo.push(["track", eventName, options]);
  }

  _track_page_visit() {
    let options = this._getQueryParams();
    options["title"] = document.title;
    klaviyo.push(["track", "Visited Page", options]);
  }

  _getQueryParams() {
    let query_params = new URLSearchParams(window.location.search);
    let query_params_hash = {};
    query_params.forEach((value, key) => {
      query_params_hash[key] = value;
    });

    return query_params_hash;
  }
}
